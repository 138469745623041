import React from 'react';
import styles from './styles.module.scss';

const ApplyButton = (props) => {
    const { text, type, disabled } = props;

    return (
        <button className={styles.ApplyButton} type={type} disabled={disabled}>
            {text}
        </button>
    );
};

export default ApplyButton;