import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Tabs, Tab, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { editTemplate, getTemplates } from '../../../redux/actions/admin';
import NewTemplate from './components/NewTemplate';
import EditTemplate from './components/EditTemplate';
import styles from './Templates.module.scss';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3, pb: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Templates = () => {
  const template = useSelector((state) => state.admin.template);
  const templates = useSelector((state) => state.admin.templates);
  const dispatch = useDispatch();

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    dispatch(editTemplate(null));
    setValue(newValue);
  };

  const getData = () => {
    setValue(0);
    dispatch(editTemplate(null));
    dispatch(getTemplates());
  }

  useEffect(() => {
    if (template) {
      setValue(2);
    }
  }, [template])

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <Box sx={{ width: '100%' }}>
        <div className={styles.TabsWrapper}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered>
            <Tab label="Templates" {...a11yProps(0)} />
            <Tab label="New" {...a11yProps(1)} />
            {template && (
              <Tab label="Edit" {...a11yProps(2)} />
            )}
          </Tabs>
        </div>
        <TabPanel value={value} index={0}>
            {templates && templates.length > 0 && templates.map(template => (
                <div className={styles.TemplateCard}>
                    {template.title}
                    <IconButton onClick={() => dispatch(editTemplate(template))}>
                        <EditIcon />
                    </IconButton>
                </div>
            ))}
        </TabPanel>
        <TabPanel value={value} index={1}>
          <NewTemplate onFinish={getData} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          {template && (
            <EditTemplate template={template} onFinish={getData} />
          )}
        </TabPanel>
      </Box>
    </div>
  );
};

export default Templates;