import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { Container, Button, Menu, MenuItem} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { logoutUser } from '../../redux/actions/auth';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import SchoolIcon from '@mui/icons-material/School';
import PeopleIcon from '@mui/icons-material/People';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

import './dashboard.scss';

const Dashboard = ({children}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [open, setOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState()

    const handleClickMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl("");
    };

    const toggle = () => {
        setOpen(!open)
    }

    const logOutHandler = () => {
        dispatch(logoutUser());
        navigate("/");
    }
    
    return(
        <div className="dashboard">
            <div className={open ? "sidebar-active" : "sidebar"}>

                <div className="logo_content">
                    <div className="logo" style={{ padding: '10px'}}>
                        
                    </div>
                    <div onClick={toggle} className="toggle-icon" style={{ padding: '10px'}}>
                        {open ? <ArrowBackIcon style={{ cursor: 'pointer'}} /> : <MenuIcon style={{ cursor: 'pointer'}} />}
                    </div>
                </div>

                <ul className="nav_list">
                    <li>
                        <Link to="/admin/trainings">
                            <div className="icon"><SchoolIcon /></div>
                            <span className="link_name">Trajnimet</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/admin/templates">
                            <div className="icon"><InsertDriveFileIcon /></div>
                            <span className="link_name">Shabllonet</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/admin/trainers">
                            <div className="icon"><PeopleIcon /></div>
                            <span className="link_name">Trajnerat</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/admin/subscribers">
                            <div className="icon"><SubscriptionsIcon /></div>
                            <span className="link_name">Abonimet</span>
                        </Link>
                    </li>
                    <div className="divider">
                        <div className="logout-button" style={{marginBottom: '70px', cursor: 'pointer'}}>
                            <LogoutIcon onClick={logOutHandler} />
                        </div>
                    </div>
                </ul>

                
            </div>
            <div className={open ? "container-active" : "container"}>
                <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                    {children}
                </Container>
            </div>
        </div>
    )
}

export default Dashboard;