import * as c from "../constants";

const initialState = () => {
    return {
        trainings: null,
        training: null,
        trainers: null,
    }
}

export const appReducer = (state = initialState(), action) => {
    switch(action.type){
        case c.ACTIVE_TRAININGS:
            return { ...state, trainings: action.payload }
        case c.GET_TRAINING:
            return { ...state, training: action.payload }
        case c.GET_ACTIVE_TRAINERS:
            return { ...state, trainers: action.payload }
        default:
            return state
    }
}
