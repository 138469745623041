import React from 'react';
import Training from '../../../../components/admin/trainer/Trainer';

const TrainersList = (props) => {
    const { trainers } = props;

    return (
        <div>
            {trainers && trainers.length > 0 ? (
                trainers.map((trainer) => (
                    <Training trainer={trainer} />
                ))
            ) : (
                <p>Nuk ka trajnera</p>
            )}
        </div>
    );
};

export default TrainersList;
