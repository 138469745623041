import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, TextField, FormControl, Select, InputLabel, MenuItem, LinearProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getTraining } from '../../redux/actions/app';
import moment from 'moment';
import styles from './Training.module.scss';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom';
import ApplyButton from '../../components/buttons/ApplyButton';
import { applyForTraining } from '../../redux/actions/app';
import toast from 'react-hot-toast';

const Training = () => {
    const navigate = useNavigate();
    const { slug } = useParams();
    const training = useSelector((state) => state.app.training);
    const dispatch = useDispatch();

    const [loading, setLoading] = useState();
    const [name, setName] = useState();
    const [surname, setSurname] = useState();
    const [proffesion, setProffesion] = useState();
    const [employementStatus, setEmployementStatus] = useState();
    const [company, setcompany] = useState();
    const [email, setEmail] = useState();
    const [phone, setPhone] = useState();
    const [city, setCity] = useState();
    const [info, setInfo] = useState();

    useEffect(() => {
        dispatch(getTraining(slug));
    }, [])

    const onClientApply = (e) => {
        e.preventDefault();
        setLoading(true);
        
        const data = {
            "training_id": training.id,
            "name": name,
            "surname": surname,
            "profession": proffesion,
            "employement_status": employementStatus,
            "company": company,
            "email": email,
            "phone": phone,
            "city": city,
            "info": info
        };

        dispatch(applyForTraining(data)).then(res => {
            toast.success('Aplikimi juaj ishte i suksesshëm!');
            setLoading(false);
            setName("");
            setSurname("");
            setProffesion("");
            setEmployementStatus("");
            setcompany("");
            setEmail("");
            setPhone("");
            setCity("");
            setInfo("");
        }).catch(err => {
            setLoading(false);
            toast.error('Dicka shkoi keq, ju lutem provoni perseri!');
            setName("");
            setSurname("");
            setProffesion("");
            setEmployementStatus("");
            setcompany("");
            setEmail("");
            setPhone("");
            setCity("");
            setInfo("");
        });
    }

    return (
        <Container>
            {training && (
                <div>
                    <div className={styles.TrainingTitle}>
                        <div className={styles.CategoryName} style={{ backgroundColor: training.category.color }} onClick={() => navigate('/trainings')}>
                            <ArrowBackIosIcon fontSize='small' />
                        </div>
                        <div className='text-center'>
                            <h3>
                                {training.title}
                            </h3>
                            <p>
                                Grupi fillon me <span>{moment(training.training_start).format('DD/MM/YYYY')}</span>
                            </p>
                        </div>
                        <div className={styles.CategoryName} style={{ backgroundColor: training.category.color }}>
                            
                        </div>
                    </div>
                    <div className={styles.Description}>
                        <div dangerouslySetInnerHTML={{__html: training.description}}></div>  
                    </div>
                    <form onSubmit={onClientApply} className={styles.ClientFormWrapper}>
                        <h4>Forma për aplikim</h4>
                        <p>Ju lutem ti plotësoni të gjitha fushat e nevojshme në mënyrë që aplikimi të jetë i suksesshëm</p>
                        <hr></hr>
                        <div className='row'>
                            <div className='col-md-4'>
                                <TextField
                                    className='mt-2'
                                    variant="outlined"
                                    fullWidth
                                    label="Emri"
                                    size="small"
                                    value={name}
                                    required
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                            <div className='col-md-4'>
                                <TextField
                                    className='mt-2'
                                    variant="outlined"
                                    fullWidth
                                    label="Mbiemri"
                                    size="small"
                                    value={surname}
                                    required
                                    onChange={(e) => setSurname(e.target.value)}
                                />
                            </div>
                            <div className='col-md-4'>
                                <TextField
                                    className='mt-2'
                                    variant="outlined"
                                    fullWidth
                                    label="Profesioni juaj"
                                    size="small"
                                    value={proffesion}
                                    required
                                    onChange={(e) => setProffesion(e.target.value)}
                                />
                            </div>
                            <div className='col-md-4'>
                                <FormControl variant="outlined" fullWidth={true} size="small" className='mt-2'>
                                    <InputLabel id="demo-simple-select-outlined-label">A punoni?</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={employementStatus}
                                        onChange={(e) => setEmployementStatus(e.target.value)}
                                        label="A punoni?"
                                    >
                                        <MenuItem value=""></MenuItem>
                                        <MenuItem value="Po">Po</MenuItem>
                                        <MenuItem value="Jo">Jo</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className='col-md-4'>
                                <TextField
                                    className='mt-2'
                                    variant="outlined"
                                    fullWidth
                                    label="Kompania"
                                    size="small"
                                    value={company}
                                    onChange={(e) => setcompany(e.target.value)}
                                />
                            </div>
                            <div className='col-md-4'>
                                <TextField
                                    className='mt-2'
                                    variant="outlined"
                                    fullWidth
                                    label="E-mail"
                                    type="email"
                                    size="small"
                                    value={email}
                                    required
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                            <div className='col-md-4'>
                                <TextField
                                    className='mt-2'
                                    variant="outlined"
                                    fullWidth
                                    label="Numri i telefonit"
                                    size="small"
                                    value={phone}
                                    required
                                    onChange={(e) => setPhone(e.target.value)}
                                />
                            </div>
                            <div className='col-md-4'>
                                <TextField
                                    className='mt-2'
                                    variant="outlined"
                                    fullWidth
                                    label="Qyteti"
                                    size="small"
                                    value={city}
                                    required
                                    onChange={(e) => setCity(e.target.value)}
                                />
                            </div>
                            <div className='col-md-4'>
                                <TextField
                                    className='mt-2'
                                    variant="outlined"
                                    fullWidth
                                    label="Ku keni dëgjuar për këtë trajnim"
                                    size="small"
                                    value={info}
                                    required
                                    onChange={(e) => setInfo(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='text-center mt-4'>
                            <ApplyButton text="Apliko" type="submit" />
                            {loading && (<LinearProgress className='mt-1' />)}
                        </div>
                    </form>
                </div>
            )}
        </Container>
    );
};

export default Training;