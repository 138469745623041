import React, { useState } from 'react';
import { Container } from '@mui/material';
import HomeImg from '../../assets/images/home-img.png';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import styles from './Home.module.scss';
import Transition from '../../components/Transition';
import { Dialog, TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import { subscribe } from '../../redux/actions/app';
import toast from 'react-hot-toast';

const Home = () => {
    const dispatch = useDispatch();
    const [modal, setModal] = useState(false);

    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [contact, setContact] = useState();

    const openModal = () => {
        setModal(true);
    }

    const onAbonateHandler = (e) => {
        e.preventDefault();
        const data = {
            "name": name,
            "email": email,
            "contact": contact
        }
        dispatch(subscribe(data)).then(res => {
            toast.success('Ju jeni abonuar me sukses!');
            setModal(false);
            setName("");
            setEmail("");
            setContact("");
        }).catch(err => {
            setModal(false);
            setName("");
            setEmail("");
            setContact("");
            toast.error('Ju jeni i abonuar. Nuk mund të abonoheni me të njejtën email adresë.');
        });
    }

    return (
        <Container>
            <div className={styles.HomeWrapper}>
                <div className={styles.Description}>
                    <h1>HR BEE ACADEMY</h1>
                    <div className={styles.Divider}></div>
                    <h4>
                        NE BËJMË <span>DIFERENCËN NË FUSHËN E HR/BNJ </span>
                        DHE <span> ZHVILLOJMË AFTËSIT </span> E JUAJA PËR
                        <span> MENAXHIMIN E HR/BNJ</span>
                    </h4>
                    <div className='mt-5'>
                        <p>Trajnimi janë të përshtatshëm për:</p>
                        <div className='mt-3'>
                            <p>Pronar biznesi, Menaxher i Burimeve Njerëzore, Asistent i Burimeve Njerëzore,</p>
                            <p>Student dhe të tjerë që dëshirojnë për të filluar karrierën e tyre në HR/BNJ.</p>
                        </div>
                    </div>
                    <div className='mt-5'>
                        <PrimaryButton text="Abonohu falas" onClick={() => openModal()} />
                        <p className='mt-2'>Për tu informuar rreth ngjarjeve, trendeve dhe ndryshimeve në fushën e HR/BNJ</p>
                    </div>
                </div>
                <div>
                    <img src={HomeImg} alt="Home" width="100%" />
                </div>
            </div>

            <Dialog
                open={modal}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setModal(false)}
                aria-describedby="alert-dialog-slide-description"
                maxWidth="md"
            >
                <div style={{ padding: '20px'}}>
                    <div style={{ width: '350px' }}></div>
                    <div className='d-flex align-items-center justify-content-between'>
                        <p className={styles.ModalTitle}>Abonohu</p>
                    </div>
                    <form onSubmit={onAbonateHandler}>
                        <div>
                            <TextField
                                className='my-2'
                                variant="outlined"
                                fullWidth
                                label="Emri dhe mbiemri"
                                size="small"
                                value={name}
                                required
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                        <div>
                            <TextField
                                className='my-2'
                                variant="outlined"
                                fullWidth
                                label="E-mail"
                                size="small"
                                type="email"
                                value={email}
                                required
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div>
                            <TextField
                                className='my-2'
                                variant="outlined"
                                fullWidth
                                label="Numri kontaktues"
                                size="small"
                                value={contact}
                                required
                                onChange={(e) => setContact(e.target.value)}
                            />
                        </div>
                        <div className='text-center mt-3'>
                            <PrimaryButton type="submit" text="Abonohu" />
                        </div>
                    </form>
                </div>
            </Dialog>
        </Container>
    );
};

export default Home;