import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { TextField, LinearProgress } from "@mui/material";
import { postTemplate } from '../../../../redux/actions/admin';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import PrimaryButton from '../../../../components/buttons/PrimaryButton';
import toast from 'react-hot-toast';
import styles from './styles.module.scss';

const NewTemplate = (props) => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);

    const [title, setTitle] = useState();
    const [description, setDescription] = useState();

    const onChangeDescription = (e, editor) => {
        setDescription(editor.getData())
    }

    const onCreateTrainingHandler = (e) => {
        e.preventDefault();
        setLoading(true);

        const data = {
            "title": title,
            "description": description
        }

        dispatch(postTemplate(data)).then(res => {
            toast.success('Shablloni u krijua me sukses!');
            setLoading(false);
            props.onFinish();
        }).catch(err => {
            setLoading(false);
            toast.error('Dicka shkoi keq, ju lutem provoni perseri!');
        });
    }

    return (
        <div className={styles.FormWrapper}>
            <form onSubmit={onCreateTrainingHandler}>
                <div className='row'>
                    <div className='col-md-12'>
                        <TextField
                            className='my-2'
                            variant="outlined"
                            fullWidth
                            label="Titulli i shabllonit"
                            size="small"
                            value={title}
                            required
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    </div>
                </div>
                <div className='row mt-4'>
                    <div className='col-md-12'>
                        <CKEditor
                            className={styles.Editor}
                            editor={ClassicEditor}
                            data={description}
                            onChange={onChangeDescription}
                            config={{
                                toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', 'numberedList', 'bulletedList', '|', 'alignment'],
                                alignment: {
                                    options: ['left', 'center', 'right']
                                  }
                            }}
                            onReady={(editor) => {
                                editor.editing.view.change((writer) => {
                                    writer.setStyle(
                                        "height",
                                        "400px",
                                        editor.editing.view.document.getRoot()
                                    );
                                    // writer.setStyle(
                                    //     "text-align",
                                    //     "center",
                                    //     editor.editing.view.document.getRoot()
                                    // );
                                });
                            }}
                        />
                    </div>
                </div>
                <div className='text-center mt-4'>
                    <PrimaryButton text="Ruaj" type="submit" disabled={loading} />
                    {loading && (<LinearProgress className='mt-1' />)}
                </div>
            </form>
        </div>
    );
};

export default NewTemplate;
