import React from 'react';
import styles from './styles.module.scss';

const PrimaryButton = (props) => {
    const { text, type, disabled, onClick } = props;

    return (
        <button className={styles.PrimaryButton} type={type} onClick={onClick} disabled={disabled}>
            {text}
        </button>
    );
};

export default PrimaryButton;