import * as c from "../constants";
import API from '../api';

// Get subscribers
export const getSubscribers = () => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.get('/subscribes', config)
        dispatch({ type: c.GET_SUBSCRIBERS, payload: response.data})

        return response
    } catch (error) {
        throw error
    }
}

// Get Categories
export const getCategories = () => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.get('/categories', config)
        dispatch({ type: c.GET_CATEGORIES, payload: response.data})

        return response
    } catch (error) {
        throw error
    }
}

// Get Trainers
export const getTrainers = () => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.get('/trainers', config)
        dispatch({ type: c.GET_TRAINERS, payload: response.data})

        return response
    } catch (error) {
        throw error
    }
}

// Post Trainer
export const postTrainer = (data) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.post('/trainers', data, config)

        return response
    } catch (error) {
        throw error
    }
}

// Update Trainer
export const updateTrainer = (id, data) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.put(`/trainers/${id}`, data, config)
        dispatch({ type: c.UPDATE_TRAINER, trainer: response.data.trainer })

        return response
    } catch (error) {
        throw error
    }
}

// Delete Trainer
export const deleteTrainer = (id) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.delete(`/trainers/${id}`, config)
        dispatch({ type: c.DELETE_TRAINER, payload: id})

        return response
    } catch (error) {
        throw error
    }
}

// Get Trainings
export const getTrainings = () => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.get('/trainings', config)
        dispatch({ type: c.GET_TRAININGS, payload: response.data})

        return response
    } catch (error) {
        throw error
    }
}

// Post Training
export const postTraining = (data) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.post('/trainings', data, config)

        return response
    } catch (error) {
        throw error
    }
}

// Update Training
export const updateTraining = (training, data, type) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.put(`/trainings/${training.id}`, data, config)
        if (type === 'deleted') {
            dispatch({ type: c.DELETE_TRAINING, training: training })
        }

        return response
    } catch (error) {
        throw error
    }
}

// Delete Client
export const deleteClient = (training, id) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.delete(`/clients/${id}`, config)
        dispatch({ type: c.DELETE_CLIENT, training: training, client: id})

        return response
    } catch (error) {
        throw error
    }
}

// Update Client
export const updateClient = (client, data, type) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.put(`/clients/${client.id}`, data, config)
        if (type === 'bill'){
            dispatch({ type: c.CHANGE_CLIENT_BILL, client: client, bill: data.bill_number })
        }
        if (type === 'transfer') {
            dispatch({ type: c.CHANGE_CLIENT_TRAINING, oldTraining: client.training_id, client: client, newTraining: data.training_id})
        } else {
            dispatch({ type: c.CHANGE_CLIENT_PAYMENT, client: client, status: data.status })
        }

        return response
    } catch (error) {
        throw error
    }
}

// Edit Training
export const editTraining = (training) => async(dispatch) => {
    dispatch({ type: c.EDIT_TRAINING, training: training })
}
// Edit Trainer
export const editTrainer = (trainer) => async(dispatch) => {
    dispatch({ type: c.EDIT_TRAINER, trainer: trainer })
}

// Get Templates
export const getTemplates = () => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.get('/templates', config)
        dispatch({ type: c.GET_TEMPLATES, payload: response.data})

        return response
    } catch (error) {
        throw error
    }
}

// Post Template
export const postTemplate = (data) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.post('/templates', data, config)

        return response
    } catch (error) {
        throw error
    }
}

// Update Template
export const updateTemplate = (template, data) => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.put(`/templates/${template.id}`, data, config)

        return response
    } catch (error) {
        throw error
    }
}

// Edit Template
export const editTemplate = (template) => async(dispatch) => {
    dispatch({ type: c.EDIT_TEMPLATE, template: template })
}