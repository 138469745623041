import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Tabs, Tab } from '@mui/material';
import { getCategories, getTrainings, editTraining, getTemplates } from '../../../redux/actions/admin';
import ActiveTrainings from './components/ActiveTrainings';
import CompletedTrainings from './components/CompletedTrainings';
import NewTraining from './components/NewTraining';
import EditTraining from './components/EditTraining';
import moment from 'moment';
import styles from './Trainings.module.scss';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3, pb: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Trainings = () => {
  const trainings = useSelector((state) => state.admin.trainings);
  const training = useSelector((state) => state.admin.training);
  const templates = useSelector((state) => state.admin.templates);
  const dispatch = useDispatch();

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    dispatch(editTraining(null));
    setValue(newValue);
  };

  const getData = () => {
    setValue(0);
    dispatch(editTraining(null));
    dispatch(getCategories()).then(res => {
      dispatch(getTrainings()).then(res => {
        dispatch(getTemplates());
      });
    });
  }

  useEffect(() => {
    if (training) {
      setValue(3);
    }
  }, [training])

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <Box sx={{ width: '100%' }}>
        <div className={styles.TabsWrapper}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered>
            <Tab label="Active" {...a11yProps(0)} />
            <Tab label="Completed" {...a11yProps(1)} />
            <Tab label="New" {...a11yProps(2)} />
            {training && (
              <Tab label="Edit" {...a11yProps(3)} />
            )}
          </Tabs>
        </div>
        <TabPanel value={value} index={0}>
          <ActiveTrainings
            trainings={trainings && trainings.length > 0
              ?
              trainings.filter((training) => training.status === 1 && moment(training.training_start).format('YYYY-MM-DD') > moment().format('YYYY-MM-DD'))
              :
              []}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <CompletedTrainings
            trainings={trainings && trainings.length > 0
              ?
              trainings.filter((training) => training.status === 1 && moment(training.training_start).format('YYYY-MM-DD') <= moment().format('YYYY-MM-DD'))
              :
              []}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <NewTraining onFinish={getData} templates={templates} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          {training && (
            <EditTraining training={training} onFinish={getData} />
          )}
        </TabPanel>
      </Box>
    </div>
  );
};

export default Trainings;