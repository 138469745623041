import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSubscribers } from '../../../redux/actions/admin';
import * as FileSaver from 'file-saver';
import XLSV from 'sheetjs-style';
import styles from './styles.module.scss';

const Subscribers = () => {
    const dispatch = useDispatch();
    const subscribers = useSelector((state) => state.admin.subscribers);

    useEffect(() => {
        dispatch(getSubscribers());
    }, [])

    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const exportToExcel = async() => {
        const dataToExport = subscribers.map(item => ({
            Name: item.name,
            Contact: item.contact,
            Email: item.email
        }));
        const ws = XLSV.utils.json_to_sheet(dataToExport);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSV.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, 'academy-subscribers' + fileExtension);
    }

    return (
        <div className={styles.Wrapper}>
            <div className='d-flex justify-content-end mb-3'>
                <button onClick={() => exportToExcel()} className={styles.ExportButton}>
                    Export
                </button>
            </div>
            {subscribers && subscribers.length > 0 && subscribers.map((sub, index) => (
                <div className={styles.Item} key={index}>
                    <p>{index + 1}</p>
                    <p>{sub.name}</p>
                    <p>{sub.email}</p>
                    <p>{sub.contact}</p>
                </div>
            ))}
        </div>
    );
};

export default Subscribers;