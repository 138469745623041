import React from 'react';
import styles from './Footer.module.scss';

const Footer = () => {

    return (
        <div className={styles.Footer}>
            <p>Akademia e liderëve të ardhshëm në fushën e HR/BNJ. Të gjitha të drejtat janë të rezervuara © 2023</p>
            <p>WhatsApp/Viber +383 48 48 99 99 <span className='mx-2'>|</span> academy@hr-bee.com</p>
        </div>
    );
};

export default Footer;