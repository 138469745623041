import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AdminRoute from './routes/AdminRoute';
import PublicRoute from './routes/PublicRoute';

import Home from './pages/home/Home';
import Login from './pages/login/Login';
import Trainings from './pages/trainings/Trainings';
import Training from './pages/training/Training';
import Trainers from './pages/trainers/Trainers';

import TrainingsAdmin from './pages/admin/trainings/Trainings';
import TrainersAdmin from './pages/admin/trainers/Trainers';
import Subscribers from './pages/admin/subscribers/Subscribers';
import Templates from './pages/admin/templates/Templates';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" exact element={<PublicRoute><Home /></PublicRoute>} />
        <Route path="/trainers" exact element={<PublicRoute><Trainers /></PublicRoute>} />
        <Route path="/trainings" exact element={<PublicRoute><Trainings /></PublicRoute>} />
        <Route path="/training/:slug" exact element={<PublicRoute><Training /></PublicRoute>} />
        <Route path="/login" exact element={<Login />} />
        
        {/* Admin Routes  */}
        <Route path="/admin/trainings" exact element={<AdminRoute><TrainingsAdmin /></AdminRoute>} />
        <Route path="/admin/trainers" exact element={<AdminRoute><TrainersAdmin /></AdminRoute>} />
        <Route path="/admin/subscribers" exact element={<AdminRoute><Subscribers /></AdminRoute>} />
        <Route path="/admin/templates" exact element={<AdminRoute><Templates /></AdminRoute>} />
      </Routes>
    </Router>
  );
}

export default App;
