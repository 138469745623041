import * as c from "../constants";

const initialState = () => {
    return {
        categories: null,
        trainings: null,
        training: null,
        trainers: null,
        trainer: null,
        subscribers: null,
        templates: null,
        template: null,
    }
}

export const adminReducer = (state = initialState(), action) => {
    switch(action.type){
        case c.GET_TEMPLATES:
            return { ...state, templates: action.payload }
        case c.EDIT_TEMPLATE:
            return { ...state, template: action.template }
        case c.GET_SUBSCRIBERS:
            return { ...state, subscribers: action.payload }
        case c.GET_CATEGORIES:
            return { ...state, categories: action.payload }
        case c.GET_TRAINERS:
            return { ...state, trainers: action.payload }
        case c.GET_TRAININGS:
            return { ...state, trainings: action.payload }
        case c.DELETE_CLIENT:
            return { 
                    ...state,
                    trainings: state.trainings.map(training => {
                        if (training.id === action.training) {
                            return {
                                ...training,
                                clients: training.clients.filter(client => client.id !== action.client)
                            }
                        } else{
                            return training;
                        }
                    })
                }
        case c.CHANGE_CLIENT_TRAINING:
            return {
                ...state,
                trainings: state.trainings.map(training => {
                    if (training.id === action.oldTraining) {
                        return {
                            ...training,
                            clients: training.clients.filter(client => client.id !== action.client.id)
                        }
                    } else if (training.id === action.newTraining) {
                        return {
                            ...training,
                            clients: [ ...training.clients, { ...action.client, training_id: action.newTraining}]
                        }
                    } else {
                        return training;
                    }
                })
            }
        case c.CHANGE_CLIENT_PAYMENT:
            return {
                ...state,
                trainings: state.trainings.map(training => {
                    if (training.id === action.client.training_id) {
                        return {
                            ...training,
                            clients: training.clients.map(client => {
                                if (client.id === action.client.id) {
                                    return {...client, status: action.status}
                                } else {
                                    return client;
                                }
                            })
                        }
                    } else{
                        return training;
                    }
                })
            }
        case c.CHANGE_CLIENT_BILL:
                return {
                    ...state,
                    trainings: state.trainings.map(training => {
                        if (training.id === action.client.training_id) {
                            return {
                                ...training,
                                clients: training.clients.map(client => {
                                    if (client.id === action.client.id) {
                                        return {...client, bill_number: action.bill}
                                    } else {
                                        return client;
                                    }
                                })
                            }
                        } else{
                            return training;
                        }
                    })
                }
        case c.DELETE_TRAINING:
            return { 
                ...state,
                trainings: state.trainings.map(training => {
                    if (training.id === action.training.id) {
                        return { ...training, status: 0 }
                    } else{
                        return training;
                    }
                })
            }
        case c.DELETE_TRAINER:
            return {
                ...state,
                trainers: state.trainers.filter(trainer => trainer.id !== action.payload)
            }
        case c.EDIT_TRAINING:
            return {
                ...state,
                training: action.training
            }
        case c.EDIT_TRAINER:
            return {
                ...state,
                trainer: action.trainer
            }
        case c.UPDATE_TRAINER:
            return {
                ...state,
                trainers: state.trainers.map(trainer => {
                    if(trainer.id === action.trainer.id){
                        return action.trainer;
                    } else {
                        return trainer;
                    }
                })
            }
        default:
            return state
    }
}
