import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Tabs, Tab } from '@mui/material';
import { getTrainers, editTrainer } from '../../../redux/actions/admin';
import NewTrainer from './components/NewTrainer';
import TrainersList from './components/TrainersList';
import moment from 'moment';
import styles from './Trainers.module.scss';
import EditTrainer from './components/EditTrainer';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3, pb: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Trainers = () => {
  const trainers = useSelector((state) => state.admin.trainers);
  const trainer = useSelector((state) => state.admin.trainer);
  const dispatch = useDispatch();

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    dispatch(editTrainer(null));
    setValue(newValue);
  };

  const getData = () => {
    dispatch(editTrainer(null));
    setValue(0);
    dispatch(getTrainers());
  }

  useEffect(() => {
    if (trainer) {
      setValue(2)
    }
  }, [trainer])

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <Box sx={{ width: '100%' }}>
        <div className={styles.TabsWrapper}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered>
            <Tab label="Trainers" {...a11yProps(0)} />
            <Tab label="New" {...a11yProps(1)} />
            {trainer && (
              <Tab label="Edit" {...a11yProps(2)} />
            )}
          </Tabs>
        </div>
        <TabPanel value={value} index={0}>
            <TrainersList trainers={trainers} />
        </TabPanel>
        <TabPanel value={value} index={1}>
            <NewTrainer onFinish={getData} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          {trainer && (
            <EditTrainer trainer={trainer} onFinish={getData} />
          )}
        </TabPanel>
      </Box>
    </div>
  );
};

export default Trainers;