import * as c from "../constants";

const initialState = () => {
    return {
        token: '',
        user: null,
    }
}

export const authReducer = (state = initialState(), action) => {
    switch(action.type){
        case c.LOGIN:
            return { ...state, token: action.payload.accessToken, user: action.payload.user }
        case c.LOGOUT: 
            return { ...state, token: '', user: null }
        default:
            return state
    }
}
