import React, { useState } from 'react';
import { Dialog, IconButton, FormControl, InputLabel, Select, MenuItem, TextField, } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { deleteClient } from '../../../redux/actions/admin';
import { useDispatch, useSelector } from 'react-redux';
import { updateClient } from '../../../redux/actions/admin';
import Transition from '../../Transition';
import styles from './Client.module.scss';
import toast from 'react-hot-toast';
import moment from 'moment';
import PrimaryButton from '../../buttons/PrimaryButton';

const Client = (props) => {
    const dispatch = useDispatch();
    const trainings = useSelector((state) => state.admin.trainings);
    const { client, trainingId } = props;

    const [more, setMore] = useState(false);
    const [transferModal, setTransferModal] = useState(false);
    const [billModal, setBillModal] = useState(false);
    const [training, setTraining] = useState(client.training_id);
    const [billNumber, setBillNumber] = useState(client.bill_number);

    const deleteClientHandler = () => {
        dispatch(deleteClient(trainingId, client.id)).then(res => {
            toast.success('Klienti u fshi me sukses!');
        }).catch(err => {
            toast.error('Dicka shkoi keq, ju lutem provoni perseri!');
        });
    }

    const changeTrainingOfClient = (e) => {
        setTraining(e.target.value);

        const data = {
            training_id: e.target.value
        }

        dispatch(updateClient(client, data, 'transfer')).then(res => {
            toast.success('Trajnimi i klientit u ndryshua me sukses!');
            setTransferModal(false);
            setMore(false);
            props.onCloseClients();
        }).catch(err => {
            toast.error('Dicka shkoi keq, ju lutem provoni perseri!');
            setTransferModal(false);
            setMore(false);
            props.onCloseClients();
        });
    }

    const onUpdateClient = (e) => {
        e.preventDefault();
        const data = {
            bill_number: billNumber
        }

        dispatch(updateClient(client, data, 'bill')).then(res => {
            toast.success('Fatura e klientit u ndryshua me sukses!');
            setTransferModal(false);
            setMore(false);
            props.onCloseClients();
        }).catch(err => {
            toast.error('Dicka shkoi keq, ju lutem provoni perseri!');
            setTransferModal(false);
            setMore(false);
            props.onCloseClients();
        });
    }

    const changeStatus = (status) => {
        const data = {
            status: status
        }

        dispatch(updateClient(client, data, status)).then(res => {
            toast.success('Pagesa e klientit u regjistrua');
            setTransferModal(false);
        }).catch(err => {
            toast.error('Dicka shkoi keq, ju lutem provoni perseri!');
            setTransferModal(false);
        });
    }

    return (
        <div className={styles.ClientWrapper}>
            <div className={styles.MainInfo}>
                <p>{client.name} {client.surname}</p>
                <p>{client.profession}</p>
                <p>{client.email}</p>
                <p>{client.phone}</p>
                <p>{client.city}</p>
                <p  style={client.status === 'payment_finish' ? {color: 'green' } : client.status === 'cancel' ? {color: 'red'} : {}}>{client.status === 'new_application' ? 'Aplikim i ri' : client.status === 'payment_finish' ? 'Pagese e kryer' : client.status === 'confirmed' ? "E konfirmuar" : client.status === "payment_request" ? "Fatura e derguar" : "Anuluar"}</p>
                {more ? (
                    <KeyboardArrowDownIcon style={{ cursor: 'pointer' }} fontSize='small' onClick={() => setMore(false)} />
                ) : (
                    <KeyboardArrowRightIcon style={{ cursor: 'pointer' }} fontSize='small' onClick={() => setMore(true)} />
                )}
            </div>
            {more && (
                <div>
                    <div className={styles.Divider}></div>
                    <div className={styles.OtherInfos}>
                        <div className='text-center'>
                            <p>Pune aktuale</p>
                            <div className={styles.Value}>
                                {client.employement_status}
                            </div>
                        </div>
                        <div className='text-center'>
                            <p>Kompania</p>
                            <div className={styles.Value}>
                                {client.company}
                            </div>
                        </div>
                        <div className='text-center'>
                            <p>Info per trajnimin</p>
                            <div className={styles.Value}>
                                {client.info}
                            </div>
                        </div>
                        <div className='text-center'>
                            <p>Statusi</p>
                            <div className={styles.Value}>
                                {client.status === 'new_application' ? 'Aplikim i ri' : client.status === 'payment_finish' ? 'Pagese e kryer' : client.status === 'confirmed' ? "E konfirmuar" : client.status === "payment_request" ? "Fatura e derguar" : "Anuluar"}
                            </div>
                        </div>
                        <div className='text-center'>
                            <p>Fatura</p>
                            <div className={styles.Value}>
                                {client.bill_number}
                            </div>
                        </div>
                    </div>
                    <div className={styles.Divider}></div>
                    <div className={styles.ActionsButtonWrapper}>
                        <p style={{ cursor: 'pointer' }} onClick={() => setBillModal(true)}>Fatura</p>
                        <p style={{ cursor: 'pointer' }} onClick={deleteClientHandler}>Delete</p>
                        <p style={{ cursor: 'pointer' }} onClick={() => setTransferModal(true)}>Transfer</p>
                        {client.status !== 'confirmed' && (
                            <p style={{ cursor: 'pointer' }} onClick={() => changeStatus("confirmed")}>Konfirmo</p>
                        )}
                        {client.status !== 'payment_request' && (
                            <p style={{ cursor: 'pointer' }} onClick={() => changeStatus("payment_request")}>Fatura e derguar</p>
                        )}
                        {client.status !== 'payment_finish' && (
                            <p style={{ cursor: 'pointer' }} onClick={() => changeStatus("payment_finish")}>E paguar</p>
                        )}
                        {client.status !== 'cancel' && (
                            <p style={{ cursor: 'pointer' }} onClick={() => changeStatus("cancel")}>Anulo</p>
                        )}
                    </div>
                </div>
            )}
            <Dialog
                open={transferModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setTransferModal(false)}
                aria-describedby="alert-dialog-slide-description"
                maxWidth="md"
            >
                <div style={{ padding: '20px'}}>
                    <div style={{ width: '350px' }}></div>
                    <div className='d-flex align-items-center justify-content-between'>
                        <p className={styles.ApplyModalTitle}>Transfer client to another training</p>
                        <IconButton onClick={() => setTransferModal(false)}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <div>
                        <FormControl variant="outlined" fullWidth={true} size="small" className='mt-2'>
                            <InputLabel id="demo-simple-select-outlined-label">Trajnimi</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={training}
                                onChange={(e) => changeTrainingOfClient(e)}
                                label="Trajnimi"
                                required
                            >
                                {trainings && trainings.filter((training) => training.status === 1 && moment(training.training_start).format('YYYY-MM-DD') > moment().format('YYYY-MM-DD')).map(training => (
                                    <MenuItem value={training.id}>{training.title}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </div>
            </Dialog>
            <Dialog
                open={billModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setBillModal(false)}
                aria-describedby="alert-dialog-slide-description"
                maxWidth="md"
            >
                <div style={{ padding: '20px'}}>
                    <div style={{ width: '350px' }}></div>
                    <div className='d-flex align-items-center justify-content-between'>
                        <p className={styles.ApplyModalTitle}>Fatura</p>
                        <IconButton onClick={() => setBillModal(false)}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <form onSubmit={onUpdateClient}>
                        <TextField 
                            name="bill_number"
                            label="Numri i fatures"
                            value={billNumber}
                            fullWidth
                            size='small'
                            onChange={(e) => setBillNumber(e.target.value)}
                        />
                        <div className='mt-3 text-center'>
                            <PrimaryButton text="Ndrysho" />
                        </div>
                    </form>
                </div>
            </Dialog>
        </div>
    );
};

export default Client;