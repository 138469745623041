export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";

// Admin
export const EDIT_TRAINING = "EDIT_TRAINING";
export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_TRAININGS = "GET_TRAININGS";
export const DELETE_CLIENT = "DELETE_CLIENT";
export const CHANGE_CLIENT_TRAINING = "CHANGE_CLIENT_TRAINING";
export const CHANGE_CLIENT_PAYMENT = "CHANGE_CLIENT_PAYMENT";
export const DELETE_TRAINING = "DELETE_TRAINING";
export const GET_TRAINERS = "GET_TRAINERS";
export const DELETE_TRAINER = "DELETE_TRAINER";
export const GET_SUBSCRIBERS = "GET_SUBSCRIBERS";
export const EDIT_TRAINER = "EDIT_TRAINER";
export const UPDATE_TRAINER = "UPDATE_TRAINER";
export const GET_TEMPLATES = "GET_TEMPLATES";
export const EDIT_TEMPLATE = "EDIT_TEMPLATE";
export const CHANGE_CLIENT_BILL = "CHANGE_CLIENT_BILL";

// Public
export const ACTIVE_TRAININGS = "ACTIVE_TRAININGS";
export const GET_TRAINING = "GET_TRAINING";
export const GET_ACTIVE_TRAINERS = "GET_ACTIVE_TRAINERS";