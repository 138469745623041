import React, { useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Menu, MenuItem, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Container } from '@mui/material';
import styles from './Nav.module.scss';
import HrBeeLogo from '../../assets/images/logo.png';

const Nav = () => {
    const navigate = useNavigate();
    const user = useSelector((state) => state.auth.user);

    return (
        <div className={styles.NavWrapper}>
            <Container>
                <div className={styles.Nav}>
                    <img src={HrBeeLogo} className={styles.Logo} alt="Logo" onClick={() => navigate("/")} />

                    <div className='d-flex align-items-center'>
                        <Link to="/trainers" style={window.location.pathname === '/trainers' ? { fontWeight: 'bold', color: '#84CBC4'} : {}}>Trajnerat</Link>
                        <Link to="/trainings" style={window.location.pathname === '/trainings' ? { fontWeight: 'bold', color: '#84CBC4'} : {}}>Trajnimet</Link>
                        {/* {!user && (
                            <Link to="/login">Login</Link>
                        )} */}
                        {user && (
                            <Link to="/admin/trainings">Dashboard</Link>
                        )}   
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default Nav;