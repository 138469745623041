import React from 'react';
import Training from '../../../../components/admin/training/Training';

const ActiveTrainings = (props) => {
    const { trainings } = props;

    return (
        <div>
            {trainings && trainings.length > 0 ? (
                trainings.map((training) => (
                    <Training training={training} />
                ))
            ) : (
                <p>Nuk ka asnje trajnim aktiv</p>
            )}
        </div>
    );
};

export default ActiveTrainings;
