import React from 'react';
import Nav from '../components/nav-bar/Nav';
import Footer from '../components/footer/Footer';

const PublicRoute = ({ children }) => {

    return (
        <div>
            <Nav />
            <div style={{ height: '100px' }}></div>
            {children}
            <div style={{ height: '100px' }}></div>
            <Footer />
        </div>
    );
};

export default PublicRoute;