import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, LinearProgress } from "@mui/material";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import PrimaryButton from '../../../../components/buttons/PrimaryButton';
import { updateTrainer } from '../../../../redux/actions/admin';
import toast from 'react-hot-toast';
import styles from './styles.module.scss';

const EditTrainer = (props) => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);

    const [fullname, setFullName] = useState(props.trainer.fullname);
    const [position, setPosition] = useState(props.trainer.position);
    const [biography, setBiography] = useState(props.trainer.biography);

    const onChangeDescription = (e, editor) => {
        setBiography(editor.getData())
    }

    const onCreateTRainerHandler = (e) => {
        e.preventDefault();
        setLoading(true);

        const formData = new FormData();
        formData.append('fullname', fullname);
        formData.append('position', position);
        formData.append('biography', biography);

        dispatch(updateTrainer(props.trainer.id, formData)).then(res => {
            toast.success('Trajneri u ndryshua me sukses!');
            setLoading(false);
            props.onFinish();
        }).catch(err => {
            setLoading(false);
            toast.error('Dicka shkoi keq, ju lutem provoni perseri!');
        });
    }

    return (
        <div className={styles.AddNewTrainerWrapper}>
            <form onSubmit={onCreateTRainerHandler}>
                <div className='row'>
                    <div className='col-md-6'>
                        <TextField
                            className='my-2'
                            variant="outlined"
                            fullWidth
                            label="Emri dhe mbiemri"
                            size="small"
                            value={fullname}
                            required
                            onChange={(e) => setFullName(e.target.value)}
                        />
                    </div>
                    <div className='col-md-6'>
                        <TextField
                            className='my-2'
                            variant="outlined"
                            fullWidth
                            label="Pozita"
                            size="small"
                            value={position}
                            required
                            onChange={(e) => setPosition(e.target.value)}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <CKEditor
                            className={styles.Editor}
                            editor={ClassicEditor}
                            data={biography}
                            onChange={onChangeDescription}
                            config={{
                                toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', 'numberedList', 'bulletedList', '|', 'alignment'],
                                alignment: {
                                    options: ['left', 'center', 'right']
                                  }
                            }}
                            onReady={(editor) => {
                                editor.editing.view.change((writer) => {
                                    writer.setStyle(
                                        "height",
                                        "400px",
                                        editor.editing.view.document.getRoot()
                                    );
                                    // writer.setStyle(
                                    //     "text-align",
                                    //     "center",
                                    //     editor.editing.view.document.getRoot()
                                    // );
                                });
                            }}
                        />
                    </div>
                </div>
                <div className='text-center mt-4'>
                    <PrimaryButton text="Ruaj" type="submit" disabled={loading} />
                    {loading && (<LinearProgress className='mt-1' />)}
                </div>
            </form>
        </div>
    );
};

export default EditTrainer;