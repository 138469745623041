import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { trainersUrl } from '../../../global';
import styles from './Trainer.module.scss';
import DeleteButton from '../../buttons/DeleteButton';
import PrimaryButton from '../../buttons/PrimaryButton';
import { deleteTrainer, editTrainer } from '../../../redux/actions/admin';

const Trainer = (props) => {
    const dispatch = useDispatch();
    const [more, setMore] = useState(false);

    const deleteHandler = () => {
        dispatch(deleteTrainer(props.trainer.id));
    }

    return (
        <div className={styles.TrainerWrapper}>
            <div className='row text-center' onClick={() => setMore(!more)}>
                <div className='col-md-4'>
                    <img className={styles.TrainerImg} src={`${trainersUrl}${props.trainer.image}`} alt={props.trainer.fullname} />
                </div>
                <div className='col-md-4'>
                    {props.trainer.fullname}
                </div>
                <div className='col-md-4'>
                    {props.trainer.position}
                </div>
            </div>
            {more && (
                <div className='row text-center' onClick={() => setMore(!more)}>
                    <div className='mt-4'></div>
                    <hr></hr>
                    <div className='col-md-12'>
                        <div dangerouslySetInnerHTML={{__html: props.trainer.biography}}></div>  
                    </div>
                    <div className='text-center mt-3'>
                        <DeleteButton text="Delete" onClick={() => deleteHandler()} />
                        <PrimaryButton text="Ndrysho" onClick={() => dispatch(editTrainer(props.trainer))} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Trainer;