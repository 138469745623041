import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, FormControl, InputLabel, Select, MenuItem, LinearProgress } from "@mui/material";
import { postTraining } from '../../../../redux/actions/admin';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import PrimaryButton from '../../../../components/buttons/PrimaryButton';
import toast from 'react-hot-toast';
import styles from './styles.module.scss';

const NewTraining = (props) => {
    const dispatch = useDispatch();
    const categories = useSelector((state) => state.admin.categories);

    const [loading, setLoading] = useState(false);

    const [title, setTitle] = useState();
    const [category, setCategory] = useState();
    const [description, setDescription] = useState();
    const [trainingStartDate, setTrainingStartDate] = useState();
    const [image, setImage] = useState();
    const [template, setTemplate] = useState();

    const chooseTemplate = (id) => {
        setTemplate(id);
        const template = props.templates.find(item => item.id === id);
        setDescription(template.description);
        setTitle(template.title);
    }

    const onChangeDescription = (e, editor) => {
        setDescription(editor.getData())
    }

    const onCreateTrainingHandler = (e) => {
        e.preventDefault();
        setLoading(true);

        const formData = new FormData();
        formData.append('title', title);
        formData.append('category_id', category);
        formData.append('description', description);
        formData.append('training_start', trainingStartDate);
        if (image) {
            formData.append('image', image);
        }

        dispatch(postTraining(formData)).then(res => {
            toast.success('Trajnimi u krijua me sukses!');
            setLoading(false);
            props.onFinish();
        }).catch(err => {
            setLoading(false);
            toast.error('Dicka shkoi keq, ju lutem provoni perseri!');
        });
    }

    return (
        <div className={styles.AddNewTrainingWrapper}>
            <form onSubmit={onCreateTrainingHandler}>
                <div className='row'>
                    <div className='col-md-3'>
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            className='my-2'
                            variant="outlined"
                            fullWidth
                            label="Titulli i trajnimit"
                            size="small"
                            value={title}
                            required
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    </div>
                    <div className='col-md-3'>
                        <FormControl variant="outlined" fullWidth={true} size="small" className='my-2'>
                            <InputLabel id="demo-simple-select-outlined-label">Kategoria</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={category}
                                onChange={(e) => setCategory(e.target.value)}
                                label="Kategoria"
                                required
                            >
                                {categories && categories.map(category => (
                                    <MenuItem value={category.id}>{category.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className='col-md-3'>
                        <TextField
                            className='my-2'
                            variant="outlined"
                            fullWidth
                            type="date"
                            label="Fillimi i trajnimit"
                            InputLabelProps={{ shrink: true }}
                            size="small"
                            value={trainingStartDate}
                            required
                            onChange={(e) => setTrainingStartDate(e.target.value)}
                        />
                    </div>
                    <div className='col-md-3'>
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            size="small"
                            variant="outlined"
                            fullWidth
                            label="Foto"
                            name="file"
                            type="file"
                            className="my-2"
                            onChange={(e) => setImage(e.target.files[0])}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-6'>
                        <FormControl variant="outlined" fullWidth={true} size="small" className='my-2'>
                            <InputLabel id="demo-simple-select-outlined-label">Shablloni</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={template}
                                onChange={(e) => chooseTemplate(e.target.value)}
                                label="Kategoria"
                                required
                            >
                                {props.templates && props.templates.map(item => (
                                    <MenuItem value={item.id}>{item.title}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div className='row mt-4'>
                    <div className='col-md-12'>
                        <CKEditor
                            className={styles.Editor}
                            editor={ClassicEditor}
                            data={description}
                            onChange={onChangeDescription}
                            config={{
                                toolbar: ['heading', '|', 'bold', 'italic', 'blockQuote', 'numberedList', 'bulletedList', '|', 'alignment'],
                                alignment: {
                                    options: ['left', 'center', 'right']
                                  }
                            }}
                            onReady={(editor) => {
                                editor.editing.view.change((writer) => {
                                    writer.setStyle(
                                        "height",
                                        "400px",
                                        editor.editing.view.document.getRoot()
                                    );
                                    // writer.setStyle(
                                    //     "text-align",
                                    //     "center",
                                    //     editor.editing.view.document.getRoot()
                                    // );
                                });
                            }}
                        />
                    </div>
                </div>
                <div className='text-center mt-4'>
                    <PrimaryButton text="Ruaj" type="submit" disabled={loading} />
                    {loading && (<LinearProgress className='mt-1' />)}
                </div>
            </form>
        </div>
    );
};

export default NewTraining;
