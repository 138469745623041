import React from 'react';
import moment from 'moment';
import styles from './Training.module.scss';
import { useNavigate } from 'react-router-dom';

const Training = (props) => {
    const navigate = useNavigate();
    const { training } = props;

    return (
        <div className={styles.TrainingWrapper} onClick={() => navigate(`/training/${training.slug}`)}>
            <div className={styles.CategoryName} style={{ backgroundColor: training.category.color }}>
                {training.category.name}
            </div>
            <div className='text-center' style={{ cursor: 'pointer' }}>
                <h3>
                    {training.title}
                </h3>
                <p>
                    Grupi fillon me <span>{moment(training.training_start).format('DD/MM/YYYY')}</span>
                </p>
            </div>
            <div className={styles.CategoryName} style={{ backgroundColor: training.category.color }}>
                <h4>+</h4>
            </div>
        </div>
    );
};

export default Training;