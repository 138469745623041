import React, { useState } from 'react';
import Client from '../client/Client';
import { useDispatch } from 'react-redux';
import { updateTraining, editTraining } from '../../../redux/actions/admin';
import styles from './Training.module.scss';
import toast from 'react-hot-toast';

const Training = (props) => {
    const dispatch = useDispatch();
    const { training } = props;

    const [more, setMore] = useState(false);
    const [showClients, setShowClients] = useState(false);

    const openMoreOptions = () => {
        setShowClients(false);
        setMore(!more);
    }

    const openShowClients = () => {
        setShowClients(!showClients);
        setMore(false);
    }

    const deleteTrainingHandler = () => {
        const data = {
            status: 0
        }

        dispatch(updateTraining(training, data, 'deleted')).then(res => {
            toast.success('Trajnimi u fshi me sukses!');
        }).catch(err => {
            toast.error('Dicka shkoi keq, ju lutem provoni perseri!');
        });
    }

    const editTRainingHandler = () => {
        dispatch(editTraining(training));
    }

    return (
        <div className={styles.Shadow}>
            <div className={styles.TrainingWrapper}>
                <div className={styles.TrainingCategory} style={{ backgroundColor: training.category.color}}>{training.category.name}</div>
                <div className={styles.TitleInfoWrapper}>
                    <h4 className={styles.TrainingTitle}>{training.title}</h4>
                    <div className={showClients ? styles.ClientsInfoActive : styles.ClientsInfo} style={more ? {marginRight: '150px'} : {}} onClick={openShowClients}>
                        <p>Aplikime</p>
                        <p>{training.clients.length}</p>
                    </div>
                </div>
                <div className={styles.TrainingOptions}>
                    <div className='d-flex align-items-center'>
                        <div className={more ? styles.ActiveDots : styles.Dots} onClick={openMoreOptions}>...</div>
                        {more && (
                            <>
                                <div className={styles.Button} onClick={editTRainingHandler}>Edit</div>
                                <div className={styles.Button} onClick={deleteTrainingHandler}>Delete</div>
                            </>
                        )}
                    </div>
                </div>
            </div>
            {showClients && (
                <div className={styles.ClientsWrapper}>
                    {training.clients && training.clients.length > 0 && training.clients.map((client) => (
                        <Client client={client} trainingId={training.id} onCloseClients={() => setShowClients(false)} />
                    ))}
                </div>
            )}
        </div>
    );
};

export default Training;