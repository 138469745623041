import * as c from "../constants";
import API from '../api';

// Get Trainers
export const getActiveTrainers = () => async(dispatch, getState) => {
    try {
        const { auth: { token }} = getState()
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            }
        }
        const response = await API.get('/getActiveTrainers', config)
        dispatch({ type: c.GET_ACTIVE_TRAINERS, payload: response.data})

        return response
    } catch (error) {
        throw error
    }
}

// Get Active Trainings
export const getActiveTrainings = () => async(dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const response = await API.get('/getActiveTrainings', config)
        dispatch({ type: c.ACTIVE_TRAININGS, payload: response.data})

        return response
    } catch (error) {
        throw error
    }
}

// Get Single Training
export const getTraining = (slug) => async(dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const response = await API.get(`/training/${slug}`, config)
        dispatch({ type: c.GET_TRAINING, payload: response.data})

        return response
    } catch (error) {
        throw error
    }
}

// Apply To Training
export const applyForTraining = (data) => async(dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            }
        }
        const response = await API.post('/applyForTraining', data, config)

        return response
    } catch (error) {
        throw error
    }
}

// Subscribe
export const subscribe = (data) => async(dispatch) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'application/json',
            }
        }
        const response = await API.post('/subscribe', data, config)

        return response
    } catch (error) {
        throw error
    }
}