import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveTrainings } from '../../redux/actions/app';
import { Container } from '@mui/material';
import Training from './component/Training';
import styles from './Trainings.module.scss';

const Trainings = () => {
    const dispatch = useDispatch();
    const trainings = useSelector((state) => state.app.trainings);

    useEffect(() => {
        dispatch(getActiveTrainings());
    }, [])

    return (
        <div className={styles.TrainingsWrapper}>
            <Container>
                <div className='text-center'>
                    <p>Ne kemi krijuar dhe jemi duke krijuar trajnime të ndryshme të cilat për qëllim kanë të ju ndihmojnë në menaxhimin e Burimeve Njerëzore.</p>
                    <p>Në bashkpunime me profesionist të fushës së Burimeve njerëzore nga vendi dhe rajoni kemi krijuar disa module të trajnimeve</p>
                    <p className='mt-3'>Programet e trajnimeve tona</p>
                    <hr></hr>
                </div>
                <div className={styles.TrainingsList}>
                    <div className='row'>
                        {trainings && trainings.length > 0 ? (
                            trainings.map((training) => (
                                <div className='col-md-4'>
                                    <Training training={training} />
                                </div>
                            ))
                        ) : (
                            <div className='mt-4 text-center'>
                                <p>Nuk kemi trajnime aktive</p>
                            </div>
                        )}
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default Trainings;