import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveTrainers } from '../../redux/actions/app';
import { Container } from '@mui/material';
import styles from './Trainers.module.scss';
import { trainersUrl } from '../../global';

const Trainers = () => {
    const dispatch = useDispatch();
    const trainers = useSelector((state) => state.app.trainers);

    const [bio, setBio] = useState(null);
    const [active, setActive] = useState(null);

    const selectTrainer = (id, bio) => {
        if (active === id) {
            setBio(null);
            setActive(null);
        } else {
            setBio(bio);
            setActive(id);
        }
    }

    useEffect(() => {
        dispatch(getActiveTrainers());
    }, [])

    return (
        <div className={styles.TrainingsWrapper}>
            <div className='d-flex justify-content-center align-items-center h-100'>
                <Container className={styles.TitleAndTrainersWrapper}>
                    <p className={styles.Title}>Ligjëruesit e programeve të Hr bee Academy</p>
                    <div className={styles.TrainersList}>
                        {trainers && trainers.length > 0 && trainers.map(trainer => (
                            <div className='text-center'>
                                <img className={active === trainer.id ? styles.ImageShadow : styles.s} src={`${trainersUrl}${trainer.image}`} alt={trainer.fullname} onClick={() => selectTrainer(trainer.id, trainer.biography)} />
                                <p style={active === trainer.id ? {color: '#84CBC4'} : {}}><span>{trainer.fullname}</span></p>
                                <p style={active === trainer.id ? {color: '#84CBC4'} : {}}>{trainer.position}</p>
                            </div>
                        ))}
                    </div>
                    {bio && active && (
                        <div className={styles.Description}>
                            <p className={styles.SubTitle}>Biografia</p>
                            <div dangerouslySetInnerHTML={{__html: bio}}></div>  
                        </div>
                    )}
                </Container>
            </div>
        </div>
    );
};

export default Trainers;